<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto align-self-center">

    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>

<div class='row'>
  <div class='col text-center'>
    <h2><b>На дату {{dialogData.planDateSend}} і на отримувача {{dialogData.recipient}} є ще замовлення: </b></h2>
  </div>
</div>
<div class='row my-2' *ngFor='let item of dialogData.orderList'>
  <div class='col-auto'><b>№ {{item.zakaz}}</b> - {{item.action}} </div>
</div>

<div class='row justify-content-center mt-5'>
  <div class='col text-center'>
    <button mat-button class='btn btn-success' (click)='pack()'>
      Упакувати вибране замовлення окремо
    </button>
  </div>
  <div class='col text-center'>
    <button mat-button class='btn btn-danger' matDialogClose>
      Чекати збору всіх замовлень
    </button>
  </div>
</div>
