import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { PackingOrder, TtnInfo } from '../core/pi';
import { PsService } from '../core/ps.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { PackingDialogSaveSelfTtnComponent } from '../packing-dialog-save-self-ttn/packing-dialog-save-self-ttn.component';
import { Router } from '@angular/router';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-pacing-dialog-create-ttn',
  templateUrl: './packing-dialog-create-ttn.component.html',
  styleUrls: ['./packing-dialog-create-ttn.component.scss'],
  providers: [PsService],
})
export class PackingDialogCreateTtnComponent implements OnInit, OnDestroy {
  form: FormGroup;
  ttnInfo$: Observable<TtnInfo>;
  createTtnInfo: TtnInfo;
  settlementSet = [];
  response = {
    code: 0,
    ttn: '',
    link: '',
  };
  disabled = false;
  checkComment: boolean;
  costOrder = 0;

  private destroy = new Subject<any>();

  constructor(
    private ps: PsService,
    private srv: SrvService,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PackingDialogCreateTtnComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.form = new FormGroup({
      sender: new FormControl('', [Validators.required]),
      senderWarehouse: new FormControl('', [Validators.required]),
      cashOnDelivery: new FormControl('', [Validators.required]),
      afterPaymentOnGoodsCost: new FormControl('', [Validators.required]),
      assessedValue: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      payment: new FormControl(1, [Validators.required]),
      dateSend: new FormControl(new Date(), [Validators.required]),
      recipient: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      recipientPhone: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      warehouseName: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
    });
    this.settlementSet.push({
      weight: '',
      height: '',
      width: '',
      length: '',
      cost: 0,
      specialCargo: false,
    });
  }

  ngOnInit(): void {
    this.onLoadTtnInfo();
  }

  setForm(): void {
    this.form.get('sender').setValue(this.createTtnInfo.sender);
    this.form
      .get('senderWarehouse')
      .setValue(this.createTtnInfo.senderWarehouse);
    this.form.get('recipient').setValue(this.createTtnInfo.recipient);
    this.form.get('recipientPhone').setValue(this.createTtnInfo.recipientPhone);
    this.form.get('warehouseName').setValue(this.createTtnInfo.warehouseName);
    this.form.get('assessedValue').setValue(this.createTtnInfo.assessedValue);
    this.form.get('cashOnDelivery').setValue(this.createTtnInfo.cashOnDelivery);
    this.form
      .get('afterPaymentOnGoodsCost')
      .setValue(this.createTtnInfo.afterPaymentOnGoodsCost);
  }

  onLoadTtnInfo(): void {
    this.ttnInfo$ = this.ps.onLoadTtnInfo(this.dialogData.orderList).pipe(
      tap((val) => {
        this.createTtnInfo = val;
        if (this.createTtnInfo.sizePositionList.length > 0) {
          this.settlementSet = [];
        }

        this.createTtnInfo.sizePositionList.forEach((value) => {
          this.costOrder += +value.cost;
          this.settlementSet.push({
            weight: +value.weight,
            height: +value.height,
            width: +value.width,
            length: +value.length,
            cost: +value.cost,
            specialCargo: +value.specialCargo,
          });
        });

        this.setForm();
      })
    );
  }

  addNewPlace(): void {
    this.settlementSet.push({
      weight: 0,
      height: 0,
      width: 0,
      length: 0,
      cost: 0,
      specialCargo: 0,
    });
  }

  deletePlace(index: number): void {
    this.settlementSet.splice(index, 1);
  }

  onCreateTtn(): any {
    if (+this.costOrder !== +this.form.get('assessedValue').value) {
      this.srv.errorMessage(
        'Оценочная стоимость упаковок не совпадает с общей'
      );

      return;
    }

    if (this.createTtnInfo.sendComment && !this.checkComment) {
      this.srv.errorMessage('Не прочитан комментарий к отгрузке');

      return false;
    }

    if (this.form.invalid) {
      return false;
    }

    const q = {
      sender: this.form.value.sender,
      senderWarehouse: this.form.value.senderWarehouse,
      dateSend: this.srv.convertDate(this.form.value.dateSend),
      afterPaymentOnGoodsCost: this.form.value.afterPaymentOnGoodsCost,
      assessedValue: this.form.get('assessedValue').value,
      cashOnDelivery: this.form.value.cashOnDelivery,
      payment: this.form.value.payment,
      pack: this.settlementSet,
      orderList: this.dialogData.orderList,
    };

    this.ps
      .onCreateTtn(q)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при создании ТТН. Обратитесь в техподдержку'
          );
          return of('Error in method onInit deletePayment ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe((val) => {
        this.response = val;
        switch (val.code) {
          case 200:
            this.srv.successMessage('ТТН успешно создана');
            this.onPrintTtn(val.ttn);
            window.open(val.link);
            break;
          case 400:
            this.srv.errorMessage('Ошика при создании ТТН');
            break;
        }
      });
  }

  onSaveTtn(): any {
    if (+this.costOrder !== +this.form.get('assessedValue').value) {
      this.srv.errorMessage(
        'Оценочная стоимость упаковок не совпадает с общей'
      );

      return;
    }

    if (this.form.invalid) {
      return false;
    }

    const q = {
      sender: this.form.value.sender,
      senderWarehouse: this.form.value.senderWarehouse,
      dateSend: this.srv.convertDate(this.form.value.dateSend),
      afterPaymentOnGoodsCost: this.form.value.afterPaymentOnGoodsCost,
      assessedValue: +this.form.get('assessedValue').value,
      cashOnDelivery: this.form.value.cashOnDelivery,
      payment: this.form.value.payment,
      pack: this.settlementSet,
      orderList: this.dialogData.orderList,
    };

    this.ps
      .onSaveTtn(q)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при создании ТТН. Обратитесь в техподдержку'
          );
          return of('Error in method onInit deletePayment ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe((val) => {
        switch (val.code) {
          case 200:
            this.srv.successMessage('Данные сохранены');
            break;
          case 400:
            this.srv.errorMessage('Ошика при сохранении');
            break;
        }
      });
  }

  onPrintTtn(ttn): void {
    this.ps
      .onPrintTtn(ttn)
      .pipe(
        catchError((err) => {
          return of('Error in method onInit deletePayment ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  onChangeDisabled(): void {
    if (this.disabled) {
      this.form.get('assessedValue').disable({ onlySelf: this.disabled });
      this.disabled = false;
    } else {
      this.form.get('assessedValue').enable({ onlySelf: this.disabled });
      this.disabled = true;
    }
  }

  saveSelfTtn(): void {
    const dialog = this.dialog.open(PackingDialogSaveSelfTtnComponent);
    dialog.afterClosed().subscribe((val) => {
      this.onSendOrderFromTtn(val.ttn, val.unitId);
    });
  }

  onSendOrderFromTtn(ttn, unitId): any {
    if (this.form.invalid) {
      return false;
    }

    const q = {
      sender: this.form.value.sender,
      senderWarehouse: this.form.value.senderWarehouse,
      dateSend: this.srv.convertDate(this.form.value.dateSend),
      afterPaymentOnGoodsCost: this.form.value.afterPaymentOnGoodsCost,
      assessedValue: this.form.get('assessedValue').value,
      cashOnDelivery: this.form.value.cashOnDelivery,
      payment: this.form.value.payment,
      pack: this.settlementSet,
      ttn,
      unitId,
      orderList: this.dialogData.orderList,
    };

    this.ps
      .onSendOrderFromTtn(q)
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при создании ТТН. Обратитесь в техподдержку'
          );
          return of('Error in method onInit deletePayment ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe((val) => {
        this.response = val;
        switch (val.code) {
          case 200:
            this.srv.successMessage('Заказ успешно упакован');
            this.dialogRef.close();
            break;
          case 400:
            this.srv.errorMessage('Ошика при упаковке заказа');
            break;
        }
      });
  }

  printCheck(): any {
    const newOrdNum: number[] = this.dialogData.orderList.map(
      (item: PackingOrder) => item
    );

    // Бросаю на нужный Url
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['print', 'check'], {
        queryParams: { newOrdNum },
      })
    );
    window.open(url, '_blank');
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  setParamData(item: any, index, num: number): void {
    switch (num) {
      case 1:
        this.settlementSet[index].weight = 0.5;
        this.settlementSet[index].height = 20;
        this.settlementSet[index].width = 20;
        this.settlementSet[index].length = 4;
        break;
      case 2:
        this.settlementSet[index].weight = 1;
        this.settlementSet[index].height = 25;
        this.settlementSet[index].width = 35;
        this.settlementSet[index].length = 4;
        break;
    }

    this.srv.successMessage('Размеры установлены');
  }

  changeCost(): void {
    this.costOrder = 0;
    this.settlementSet.forEach((item) => {
      item.cost = 0;
    });
    this.createTtnInfo.orderList.forEach((item) => {
      if (this.settlementSet[item.package - 1]) {
        this.costOrder += +item.countProd * +item.siteCost;
        this.settlementSet[item.package - 1].cost +=
          +item.countProd * +item.siteCost;
      }
    });
  }
}
